const featureFlags = {
  USE_GA4: false,
  USE_HOTJAR: false,
  postHog: {
    ENABLED: true,
    COLLECT_WHEEL_OF_NAMES_PAGEVIEWS: true,
    COLLECT_WHEEL_OF_NAMES_OTHER_EVENTS: false,
  },
};

export default featureFlags;
